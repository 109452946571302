import authConfig from './auth-config.json';

export const environment = {
  production: false,
  redirect_uri: 'https://dev.insights.bayer.com/callback',
  apiUrl: 'https://api.dev.insights.bayer.com',
  apiKey: '4_DnUrJaT5ESqFobvg0J1BGA',
  clientId: 'dhkhRg2lHo5o6YxD_HCRfqrR',
  issuer_url: 'https://fidm.eu1.gigya.com/oidc/op/v1.0',
  ...authConfig,
};
